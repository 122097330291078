<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    class="fixed max-h-fit bottom-0 w-full border-t-[#e7e8e9] border-t-2 max-w-[480px] -mx-2"
    :style="{ background: store.botNavbarBackgroundColor }"
  >
    <div class="flex flex-row px-1.5 py-2.5">
      <Button
        v-for="nav in navigations"
        :key="nav.id"
        variant="custom"
        vertical="true"
        class="basis-1/4 text-xs px-2 whitespace-nowrap"
        :style="{
          color:
            nav.title === props.active ? store.botNavbarSelectedColor : store.botNavbarDefaultColor
        }"
        @click="navigateTo(nav.type)"
      >
        <template #icon>
          <component :is="nav.icon" class="mb-1.5" />
        </template>
        {{ nav.title }}
      </Button>
    </div>
  </div>
</template>
<script setup>
import IconHome from '@/components/icons/IconHome.vue'
import IconStar from '@/components/icons/IconStar.vue'
import IconMessage from '@/components/icons/IconMessage.vue'
import IconHistory from '@/components/icons/IconHistory.vue'
import Button from '@/components/uielements/Button/Button.vue'

import { useRouter } from 'vue-router'
import { shallowRef, defineProps } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'

const store = useBrandPortalStore()
const props = defineProps({
  active: {
    type: String,
    default: ''
  }
})

const navigations = shallowRef([
  { id: 1, title: 'Home', icon: IconHome, type: 'Login' },
  { id: 2, title: 'Message Log', icon: IconMessage, type: 'MessageLog' },
  { id: 3, title: 'History', icon: IconHistory, type: 'History' },
  { id: 4, title: 'Rewards', icon: IconStar, type: 'QualifiedRedemption' }
])

const router = useRouter()

function navigateTo(type) {
  router.push({
    name: type
  })
}
</script>
