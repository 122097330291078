<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="sticky margin-0">
    <FlexRow class="justify-center w-full items-center relative py-1" :style="topNavbarBackground">
      <FlexCol class="align middle justify-center items-center">
        <img
          v-if="store.logoUrl"
          :src="store.logoUrl"
          alt="logo"
          class="active-hover"
          style="width: 2rem; height: 2rem; border-radius: 50%"
          @click="backToHome"
          draggable="false"
        />
        <h3
          v-else
          class="active-hover text-center text-lg font-poppinsBold"
          @click="backToHome"
          :style="store.topNavbarTextColorStyle"
        >
          {{ props.title }}
        </h3>
        <p
          class="text-secondary-black text-xs text-center mb-2"
          :style="store.topNavbarTextColorStyle"
        >
          Powered by
          <span class="text-mulah-red font-inter">Simple</span>
          <span class="text-black font-bold font-inter" :style="store.topNavbarTextColorStyle"
            >Loyalty</span
          >
        </p>
      </FlexCol>
      <div class="absolute right-0">
        <button
          type="button"
          class="text-white focus:outline-none me-2"
          @click="showSideNav = true"
        >
          <BurgerIcon class="w-6 h-6" :style="store.topNavbarTextColorStyle" />
        </button>
      </div>
    </FlexRow>
  </section>
  <div class="min-h-fit min-w-full">
    <slot></slot>
  </div>
  <SideNav :showSideNav="showSideNav" @closeSideNav="showSideNav = false" />
  <slot name="footer"></slot>
</template>

<script setup>
import SideNav from './SideNav/SideNav.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import BurgerIcon from '@/components/icons/IconBurger.vue'
import { ref, computed } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'
import router from '@/router/index.js'

const store = useBrandPortalStore()
const showSideNav = ref(false)

const navbarOpacity = computed(() => {
  if (store.topNavbarSpecification === 'photo') {
    return store.topNavbarOpacity
  } else {
    return '1'
  }
})

const topNavbarBackground = computed(() => {
  if (store.topNavbarSpecification === 'color') {
    return checkTopNavbarColorSpec()
  } else {
    return {
      background: `url(${store.topNavbarPhotoUrl})`,
      opacity: (100 - store.topNavbarOpacity) / 100,
      backgroundSize: 'cover'
    }
  }
})

const checkTopNavbarColorSpec = () => {
  if (store.topNavbarColorSpecification === 'flat') {
    return {
      background: store.topNavbarColor
    }
  } else {
    return {
      background: store.topNavbarGradient
    }
  }
}

const backToHome = () => {
  router
    .push({
      name: 'Login'
    })
    .catch((err) => {
      console.error('Navigation error:', err)
    })
}

const props = defineProps({
  title: String
})
</script>

<style scoped>
.active-hover {
  cursor: pointer;
}
</style>
