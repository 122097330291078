<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <FlexCol class="container min-h-screen" id="default-side-padding">
    <FlexCol class="w-full mt-10">
      <h1 class="title-3xl mt-5">Can I have your number?</h1>
      <h4 class="text-icon-grey font-normal text-base my-2">Check Your Loyalty Points & Rewards</h4>

      <FlexCol class="relative mb-5 mt-3">
        <FlexRow class="items-center">
          <Button
            variant="custom"
            class="py-2 w-1/4 px-2 text-md outline-none border-r-2 border-gray-300 border-0 rounded-none text-[#595959] items-center justify-between bg-white"
            @click="showDropdown = !showDropdown"
          >
            {{ getCountryNumber }}
            <span class="text-gray-400 me-2.5">
              <IconChevronDown />
            </span>
          </Button>
          <form class="w-3/4">
            <div class="relative">
              <input
                v-model="inputValue"
                type="tel"
                id="phone"
                class="border-none w-full focus:ring-0"
                @input="validatePhoneNumber"
                required
              />
              <template v-if="validPhoneNumber">
                <div
                  class="absolute inset-y-0 right-0 flex items-center pointer-events-none text-icon-black"
                >
                  <IconCheckFill />
                </div>
              </template>
            </div>
          </form>
        </FlexRow>
        <template v-if="showDropdown">
          <div
            class="origin-top-right absolute top-[50px] w-40 rounded-md shadow-md bg-white ring-1 ring-black ring-opacity-5"
            style="z-index: 1000"
          >
            <div
              class="py-1"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <Button
                variant="custom"
                class="block px-4 py-2 text-sm text-gray-500 outline-none bg-transparent"
                disabled
              >
                Select Country
              </Button>
              <template v-for="country in countries" :key="country.code">
                <Button
                  variant="custom"
                  class="text-black px-4 py-2 text-sm outline-none bg-transparent focus:outline-none w-full justify-between items-center"
                  :class="{
                    'text-mulah-red': country.code === selectedCountry,
                    'text-[#595959]': country.code !== selectedCountry
                  }"
                  @click="selectCountry(country.code)"
                >
                  {{ country.label }}
                  <span v-if="country.code === selectedCountry" class="ms-1 mt-0.5 text-mulah-red">
                    <IconCheckFill />
                  </span>
                </Button>
              </template>
            </div>
          </div>
        </template>

        <hr class="mt-2 border-[0.8px] border-gray-300" />
      </FlexCol>
    </FlexCol>
    <Button
      size="lg"
      class="w-full rounded-lg"
      :disabled="!validPhoneNumber"
      @click="validPhoneNumber ? sendOtp() : null"
      :style="brandPortalStore.primaryButtonStyles"
    >
      Continue
    </Button>
    <Links class="mt-10" :links="portalLinks" />
  </FlexCol>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import IconCheckFill from '@/components/icons/IconCheckFill.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import Links from '@/pages/Login/Links.vue'
import { useMutation } from '@urql/vue'
import { useBrandStore, useBrandPortalStore } from '../../stores/store'
import { usePhoneStore } from '@/stores/store'
import { AsYouType, isValidPhoneNumber, getCountryCallingCode } from 'libphonenumber-js/mobile'
import { CHECKPOINTS_VALIDATION } from '@/constants/graphql.js'
import { ref, watch, computed, onMounted, nextTick } from 'vue'

import cookie from '@/utils/cookie.js'

const store = useBrandStore()
const phoneStore = usePhoneStore()
const brandPortalStore = useBrandPortalStore()
const selectedCountry = ref('MY')
const showDropdown = ref(false)
const inputValue = ref('')
const validPhoneNumber = ref(false)
const phoneNumber = ref('')
const fullPhoneNum = ref('')
const trunkPrefix = ref('') // NOTE: https://en.wikipedia.org/wiki/Trunk_prefix
const countries = ref([
  { code: 'MY', label: 'Malaysia (+60)', number: '+60' },
  { code: 'SG', label: 'Singapore (+65)', number: '+65' },
  { code: 'ID', label: 'Indonesia (+62)', number: '+62' }
])
const pause = ref(false)
const { executeMutation } = useMutation(CHECKPOINTS_VALIDATION)
const emit = defineEmits(['nextPage'])
const portalLinks = ref([])

const processLinks = (links) => {
  if (Array.isArray(links) && links.length !== 0) {
    portalLinks.value = links
  }
}

const getCountryNumber = computed(() => {
  const finalCountry = countries.value.find((country) => country.code === selectedCountry.value)
  return finalCountry.number
})

const validatePhoneNumber = () => {
  const asYouType = new AsYouType(selectedCountry.value)

  let countryCode = ''

  switch (selectedCountry.value) {
    case 'MY':
      countryCode = '+' + getCountryCallingCode(selectedCountry.value)
      trunkPrefix.value = '0'
      break
    case 'SG':
      countryCode = '+' + getCountryCallingCode(selectedCountry.value)
      trunkPrefix.value = ''
      break
    case 'ID':
      countryCode = '+' + getCountryCallingCode(selectedCountry.value)
      // TODO: Our current database does not store the trunk prefix for INDO numbers, might need to add back this later
      // trunkPrefix.value = '0'
      break
  }
  let input = inputValue.value

  if (input.includes(countryCode)) {
    input = inputValue.value
  } else {
    input = countryCode + input
  }
  fullPhoneNum.value = asYouType.input(input)
  phoneNumber.value = asYouType.getNationalNumber()
  inputValue.value = fullPhoneNum.value.slice(countryCode.length + 1)
  validPhoneNumber.value = isValidPhoneNumber(input)
}

const sendOtp = async () => {
  if (pause.value === false) {
    pause.value = true
    const result = await executeMutation({
      phoneNumber: phoneNumber.value,
      slug: store.slugUrl,
      specification: 'default',
      websiteToken: null,
      countryCode: selectedCountry.value
    })
    if (!result.error) {
      const data = result.data.slCheckpointsValidation
      let toPage = ''

      if (data.errors.length > 0) {
        return alert(data.errors[0].message)
      } else if (data.registered === false) {
        toPage = 'Register'
      } else if (data.registered === true) {
        if (data.metaVerified === false) {
          toPage = 'Meta'
        } else if (data.metaVerified === true) {
          toPage = 'QualifiedRedemption'
        }
      }

      phoneStore.$patch({
        selectedCountry: selectedCountry.value,
        locationPath: toPage,
        trunkPrefix: trunkPrefix.value,
        phoneNumber: phoneNumber.value,
        fullPhoneNum: fullPhoneNum.value,
        metaVerified: data.metaVerified
      })

      emit('nextPage', 'SMSVerification')
    }
    pause.value = false
  }
}

function selectCountry(code) {
  selectedCountry.value = code
  showDropdown.value = false
}

watch(
  selectedCountry,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      inputValue.value = ''
      validPhoneNumber.value = false
    }
  },
  { immediate: true }
)

onMounted(async () => {
  const gReviewPhoneNumCookie = 'gRevPNum'
  if (cookie.getCookie(gReviewPhoneNumCookie)) {
    let cookieValue = JSON.parse(cookie.getCookie(gReviewPhoneNumCookie))
    selectedCountry.value = cookieValue.cc
    await nextTick()
    inputValue.value = cookieValue.pNum
    validatePhoneNumber()
  }
  if (brandPortalStore.links) {
    processLinks(brandPortalStore.links)
  }
})

watch(
  () => brandPortalStore.links,
  (newValue) => {
    if (newValue) {
      processLinks(newValue)
    }
  }
)
</script>
