import { defineStore } from 'pinia'
import { ref } from 'vue'

export const usePhoneStore = defineStore('checkPhoneInfo', {
  state: () => {
    return {
      selectedCountry: '',
      locationPath: '',
      trunkPrefix: '',
      phoneNumber: '',
      phoneNumberWithPrefix: '',
      fullPhoneNum: '',
      isLogin: '',
      metaVerified: false
    }
  }
})

export const useBrandStore = defineStore('brandStore', {
  state: () => {
    return {
      slugUrl: '',
      brandId: '',
      brandName: '',
      rewards: {},
      tnc: null,
      countryCode: 'MY',
      giveaways: [],
      memberships: [],
      links: [
        { title: 'Website Link', url: '', specification: 'website' },
        { title: 'Delivery Link', url: '', specification: 'delivery' },
        { title: 'Promotion Link', url: '', specification: 'promotion' }
      ],
      brandQueryFetching: false
    }
  }
})

export const useBrandPortalStore = defineStore('brandPortalStore', {
  state: () => ({
    logoUrl: '',
    colorPalette: '',
    coverSpecification: '',
    coverColor: '#ECEBEB',
    coverPhotoUrl: '',
    carouselTitle: '',
    carouselSpecification: '',
    carouselSpacesCustom: [],
    carouselSpacesInsta: [],
    links: [],
    lbSpecification: '',
    lbColor: '#ECEBEB',
    lbGradient: '',
    lbColorSpecification: '',
    lbPhotoUrl: '',
    membersInfoSpecification: '',
    membersInfoColor: '',
    membersInfoTextColor: '',
    membersInfoPhotoUrl: '',
    membersInfoGradient: '',
    membersInfoOpacity: '',
    membersInfoColorSpecification: '',
    topNavbarGradient: '',
    topNavbarSpecification: '',
    topNavbarColorSpecification: '',
    topNavbarColor: '#ECEBEB',
    topNavbarOpacity: '',
    topNavbarTextColor: '',
    topNavbarPhotoUrl: '',
    generalBackgroundColor: '#8a9099',
    generalBackgroundGradient: '',
    generalBackgroundColorSpecification: '',
    primaryTextColor: '#000000',
    secondaryTextColor: '#ECEBEB',
    textLinkColor: '',
    botNavbarBackgroundColor: '',
    botNavbarDefaultColor: '',
    botNavbarSelectedColor: '',
    primaryBtnColor: '#ff0000',
    primaryBtnTextColor: '#ffffff',
    primaryBtnBorderColor: '#ffffff',
    secondaryBtnColor: '#ffffff',
    secondaryBtnTextColor: '#000000',
    secondaryBtnBorderColor: '#000000',
    storeListCardColor: ''
  }),
  getters: {
    primaryTextColorStyle: (state) => ({
      color: state.primaryTextColor
    }),
    secondaryTextColorStyle: (state) => ({
      color: state.secondaryTextColor
    }),
    textLinkColorStyle: (state) => ({
      color: state.textLinkColor
    }),
    membersInfoTextColorStyle: (state) => ({
      color: state.membersInfoTextColor
    }),
    topNavbarTextColorStyle: (state) => ({
      color: state.topNavbarTextColor
    }),
    primaryButtonStyles: (state) => ({
      backgroundColor: state.primaryBtnColor,
      borderColor: state.primaryBtnBorderColor,
      color: state.primaryBtnTextColor
    }),
    secondaryButtonStyles: (state) => ({
      backgroundColor: state.secondaryBtnColor,
      borderColor: state.secondaryBtnBorderColor,
      color: state.secondaryBtnTextColor
    }),
    buttonClickedColor: (state) => ({
      backgroundColor: state.primaryBtnColor,
      borderColor: state.primaryBtnTextColor,
      color: state.primaryBtnTextColor
    }),
    buttonNotClickedColor: (state) => ({
      backgroundColor: state.primaryBtnTextColor,
      borderColor: state.primaryBtnColor,
      color: state.primaryBtnColor
    }),
    storeListCardColorStyle: (state) => ({
      background: state.storeListCardColor
    }),
    membersInfoBgColor: (state) => {
      if (state.membersInfoSpecification === 'color') {
        return checkMembersInfoColorSpec(state)
      } else {
        return {
          background: `url(${state.membersInfoPhotoUrl})`,
          opacity: (100 - state.membersInfoOpacity) / 100
        }
      }
    }
  }
})

const checkMembersInfoColorSpec = (state) => {
  if (state.membersInfoColorSpecification === 'flat') {
    return {
      background: state.membersInfoColor
    }
  } else {
    return {
      background: state.membersInfoGradient
    }
  }
}
