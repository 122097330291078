<template>
  <div
    v-if="isDeactivated"
    class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
  >
    <div
      class="h-7/12 bg-white rounded-lg shadow-lg p-6 w-11/12 sm:w-2/4 md:w-2/3 lg:w-2/3 xl:w-1/3 2xl:w-1/4 overflow-auto"
    >
      <div class="flex items-center justify-center">
        <SadSvg style="height: 100px; width: 100px; min-height: 100px; min-width: 100px" />
      </div>
      <h1 class="flex justify-center text-center text-black text-3xl font-extrabold mt-5">Campaign Deactivated</h1>
      <p class="flex justify-center mt-5 text-center">This Spin & Win Campaign has been deactivated.</p>
      <Button
        class="text-white w-full mt-10 rounded-lg h-12"
        variant="mulah"
        size="lg"
        @click="backToHome"
      >
        Close
      </Button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useBrandStore } from '@/stores/store.js'

import SadSvg from '@/components/icons/IconSadSvg.vue'
import Button from '@/components/uielements/Button/Button.vue'

const router = useRouter()
const store = useBrandStore()

const backToHome = () => {
  router.push({ path: '/' + store.slugUrl })
}

const props = defineProps({
  isDeactivated: {
    type: Boolean,
    required: true
  }
})
</script>
