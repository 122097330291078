<template>
  <svg viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.3441 1.40545C24.1239 -0.468483 20.8761 -0.46848 18.6559 1.40545L16.634 3.11206C15.6903 3.90853 14.5226 4.39226 13.2921 4.49631L10.6556 4.71927C7.76067 4.9641 5.4641 7.26067 5.21927 10.1556L4.99631 12.7921C4.89226 14.0226 4.40853 15.1903 3.61206 16.134L1.90545 18.1559C0.0315166 20.3761 0.0315196 23.6239 1.90545 25.8441L3.61206 27.866C4.40853 28.8097 4.89226 29.9775 4.99631 31.2079L5.21927 33.8445C5.4641 36.7395 7.76067 39.0361 10.6556 39.2807L13.2921 39.5036C14.5226 39.6079 15.6903 40.0916 16.634 40.8881L18.6559 42.5947C20.8761 44.4684 24.1239 44.4684 26.3441 42.5947L28.366 40.8881C29.3097 40.0916 30.4775 39.6079 31.7079 39.5036L34.3445 39.2807C37.2395 39.0361 39.5361 36.7395 39.7807 33.8445L40.0036 31.2079C40.1079 29.9775 40.5916 28.8097 41.3881 27.866L43.0947 25.8441C44.9684 23.6239 44.9684 20.3761 43.0947 18.1559L41.3881 16.134C40.5916 15.1903 40.1079 14.0226 40.0036 12.7921L39.7807 10.1556C39.5361 7.26067 37.2395 4.9641 34.3445 4.71927L31.7079 4.49631C30.4775 4.39226 29.3097 3.90853 28.366 3.11206L26.3441 1.40545ZM33.3373 17.9368C34.3847 16.8894 34.3847 15.1913 33.3373 14.144C32.2902 13.0966 30.5919 13.0966 29.5445 14.144L19.5214 24.1671L15.458 20.1038C14.4107 19.0564 12.7126 19.0564 11.6652 20.1038C10.6179 21.1511 10.6179 22.8492 11.6652 23.8965L17.625 29.8562C18.6724 30.9037 20.3705 30.9037 21.4178 29.8562L33.3373 17.9368Z"
      :fill="color"
    />
  </svg>
</template>

<script setup>
import { computed } from 'vue'

const color = computed(() => {
  return props.value === '1' ? '#000000' : '#cccccc'
})

const props = defineProps({
  value: {
    type: String,
    required: true
  }
})
</script>
