<template>
  <div class="container flex justify-center items-center" :style="cover">
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'

import { useBrandPortalStore } from '@/stores/store.js'

const store = useBrandPortalStore()

const cover = computed(() => {
  if (store.coverSpecification === 'photo') {
    return {
      backgroundImage: `url(${store.coverPhotoUrl})`,
      backgroundSize: 'cover'
    }
  } else {
    return {
      background: store.coverColor
    }
  }
})
</script>
