<template>
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_dd_1926_6127)">
      <g clip-path="url(#clip0_1926_6127)">
        <circle cx="14" cy="13" r="9.84375" fill="#FFA831" stroke="#EE8F0F" stroke-width="0.3125" />
        <circle
          cx="14"
          cy="13"
          r="8.59375"
          fill="url(#paint0_linear_1926_6127)"
          stroke="#FDBD4D"
          stroke-width="0.3125"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_dd_1926_6127"
        x="0"
        y="0"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1926_6127" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.039 0" />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_1926_6127"
          result="effect2_dropShadow_1926_6127"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1926_6127"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1926_6127"
        x1="21.1094"
        y1="17.9219"
        x2="6.1875"
        y2="9.25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FEC258" />
        <stop offset="0.493004" stop-color="#F8AA1E" />
        <stop offset="0.978599" stop-color="#FEC258" />
      </linearGradient>
      <clipPath id="clip0_1926_6127">
        <path
          d="M4 13C4 7.47715 8.47715 3 14 3C19.5228 3 24 7.47715 24 13C24 18.5228 19.5228 23 14 23C8.47715 23 4 18.5228 4 13Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>
