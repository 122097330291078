<template>
  <div class="w-full bg-gray-200 rounded-full h-2.5">
    <div class="h-2.5 rounded-full" :style="{ width: getWidth, backgroundColor: color }"></div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  width: {
    type: Number,
    default: 100
  },
  color: {
    type: String,
    default: '#ff0000'
  }
})

const getWidth = computed(() => {
  return props.width + '%'
})
</script>
