<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container mb-12">
    <template v-if="customer.fetching.value">
      <h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">Loading...</h1>
    </template>
    <template v-else-if="!customer.fetching.value">
      <FlexCol class="container min-h-[90vh] overflow-y-auto">
        <FlexCol class="justify-between" :style="brandPortalStore.membersInfoBgColor">
          <FlexRow class="my-4">
            <FlexCol class="w-full">
              <template v-if="isRegistered">
                <FlexRow class="flex items-center justify-between px-10">
                  <ArrowLeft
                    :style="brandPortalStore.membersInfoTextColorStyle"
                    class="active-hover flex-shrink-0 w-8 h-8 text-white"
                    @click="backToHome"
                  />
                  <p
                    class="text-lg text-white text-center flex-grow font-bold"
                    :style="brandPortalStore.membersInfoTextColorStyle"
                  >
                    My Points
                  </p>
                  <div class="flex-shrink-0" style="width: 24px"></div>
                </FlexRow>
                <FlexRow class="flex justify-center items-center">
                  <Coin class="w-8 h-8" />
                  <h2 class="mt-2 mb-1 font-poppinsBold text-white">
                    <span class="text-3xl" :style="brandPortalStore.membersInfoTextColorStyle">{{
                      userPoints.toLocaleString('en-US')
                    }}</span
                    >&nbsp;
                    <span :style="brandPortalStore.membersInfoTextColorStyle">Points</span>
                  </h2>
                </FlexRow>
              </template>
              <template v-else>
                <h1 class="title-3xl">Check your rewards:</h1>
              </template>
            </FlexCol>
          </FlexRow>
        </FlexCol>
        <FlexCol>
          <RewardsPanel
            :rewards="store.rewards"
            :is-registered="true"
            :user-points="userPoints"
            id="default-side-padding"
          />
        </FlexCol>
      </FlexCol>
    </template>
  </div>
  <MobileFooter class="mx-0" :active="'Rewards'" />
</template>
<script setup>
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Card from '@/components/uielements/Card/Card.vue'
import RewardsPanel from '@/components/reusable/RewardsPanel.vue'
import MembershipStar from '@/components/icons/IconMembershipStar.vue'
import ArrowLeft from '@/components/icons/IconArrowLeft.vue'
import Coin from '@/components/icons/IconCoin.vue'
import { useQuery } from '@urql/vue'
import { usePhoneStore } from '../../stores/store'
import { useBrandStore, useBrandPortalStore } from '../../stores/store'
import { computed, ref, watch } from 'vue'
import { GET_CUSTOMER_POINTS } from '@/constants/graphql'
import router from '@/router/index.js'

const name = ref('')
const userPoints = ref(0)
const membershipTitle = ref('')
const isRegistered = ref(true)
const phoneStore = usePhoneStore()
const store = useBrandStore()
const brandPortalStore = useBrandPortalStore()

const backToHome = () => {
  router.push({
    name: 'Login'
  })
}

const customer = useQuery({
  query: GET_CUSTOMER_POINTS,
  variables: {
    phoneNumber:
      phoneStore.trunkPrefix + phoneStore.phoneNumber !== '' &&
      phoneStore.trunkPrefix + phoneStore.phoneNumber !== null
        ? phoneStore.trunkPrefix + phoneStore.phoneNumber
        : phoneStore.phoneNumberWithPrefix,
    slug: store.slugUrl,
    brandId: store.brandId
  }
})

watch(customer.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    name.value = customer.data.value.slAppCustomer.name
    membershipTitle.value = customer.data.value.slAppCustomer.membership.title

    customer.data.value.slAppCustomer.brands.forEach((i) => {
      userPoints.value += i.totalAvailablePoints
    })
  }
})

const phoneNumber = computed(() => {
  const phoneNum = phoneStore.trunkPrefix + phoneStore.phoneNumber

  if (phoneNum !== '' && phoneNum !== null) {
    return phoneNum
  } else {
    return phoneStore.phoneNumberWithPrefix
  }
})
</script>

<style scoped>
.active-hover {
  cursor: pointer;
}
</style>
