<template>
  <FlexCol class="mt-3">
    <Timer
      :cookie-name="'greview-cookie'"
      :session-duration="900"
      @timer-ended="handleTimerEnded"
    />
  </FlexCol>
  <FlexCol class="mt-1">
    <p class="text-2xl font-bold">Post your Google Review</p>
    <p class="text-md text-gray-600">Follow the steps below and claim your prize!</p>
    <p
      class="text-md text-gray-600"
      v-if="props.reviewInfo.minSpend == 0 || props.reviewInfo.minSpend == null"
    >
      Limited to 1 redemption per store
    </p>
  </FlexCol>

  <FlexRow class="items-center mt-5 mb-1" style="min-height: 65px">
    <FlexCol class="w-1/6">
      <div
        class="rounded-full w-8 h-8 flex items-center justify-center bg-black text-white font-bold"
      >
        1
      </div>
    </FlexCol>
    <FlexCol class="w-5/6">
      <FlexRow class="text-base justify-between items-end mb-1">
        <FlexCol class="w-7/12 h-full">
          <p>Copy hashtags</p>
        </FlexCol>
        <FlexCol class="w-5/12 ps-1">
          <button
            @click="copyToClipboard(fullHashtag)"
            class="px-1 py-1 font-medium border border-red-500 rounded hover:font-semibold text-sm mb-0.5 hover:bg-red-500 hover:text-white"
            :style="
              hasClickedCopy
                ? brandPortalStore.buttonClickedColor
                : brandPortalStore.buttonNotClickedColor
            "
          >
            <IconCopy class="inline" style="height: 12px" />

            {{ copied ? 'Copied' : 'Copy' }}
          </button>
        </FlexCol>
      </FlexRow>
      <p class="text-sm text-blue-500 break-words">{{ fullHashtag }}</p>
    </FlexCol>
  </FlexRow>

  <FlexRow class="items-center my-5" style="min-height: 65px" v-if="hasClickedCopy">
    <FlexCol class="w-1/6">
      <div
        class="rounded-full w-8 h-8 flex items-center justify-center bg-black text-white font-bold"
      >
        2
      </div>
    </FlexCol>
    <FlexCol class="w-5/6">
      <FlexRow class="text-base items-end mb-1">
        <FlexCol class="w-7/12">
          <p class="h-full">{{ minSpendText }}</p>
        </FlexCol>
        <FlexCol class="w-5/12 ps-1 mb-0.5">
          <button
            @click="startWritingClicked"
            :style="
              hasClickedValidate
                ? brandPortalStore.buttonClickedColor
                : brandPortalStore.buttonNotClickedColor
            "
            class="px-1 py-1 font-medium border border-red-500 rounded hover:font-semibold text-sm hover:bg-red-500 hover:text-white"
          >
            {{ validateBtnText }}
          </button>
        </FlexCol>
      </FlexRow>
      <p class="text-sm text-gray-600">So that you can receive your rewards via SMS</p>
    </FlexCol>
  </FlexRow>
  <FlexRow class="items-center my-5" style="min-height: 65px" v-if="props.showLeaveReviewComponent">
    <FlexCol class="w-1/6">
      <div
        class="rounded-full w-8 h-8 flex items-center justify-center bg-black text-white font-bold"
      >
        3
      </div>
    </FlexCol>
    <FlexCol class="w-5/6">
      <FlexRow class="text-base items-end mb-1">
        <FlexCol class="w-7/12">
          <p class="h-full">Include hashtags in the review</p>
        </FlexCol>
        <FlexCol class="w-5/12 ps-1 mb-0.5">
          <button
            @click="leaveReviewClicked"
            :style="
              hasClickedLeaveReview
                ? brandPortalStore.buttonClickedColor
                : brandPortalStore.buttonNotClickedColor
            "
            class="px-1 py-1 font-medium border border-red-500 rounded hover:font-semibold text-sm hover:bg-red-500 hover:text-white"
          >
            Leave Review
          </button>
        </FlexCol>
      </FlexRow>
      <p class="text-sm text-gray-600">Include the copied hashtags in your review</p>

      <a
        class="text-sm text-red-500 underline hover:drop-shadow-sm"
        style="cursor: pointer"
        @click="showExampleModal = true"
        :style="brandPortalStore.textLinkColorStyle"
        >Click here to see an example</a
      >
    </FlexCol>
  </FlexRow>

  <template v-if="showExampleModal">
    <ExampleReviewModal @close-modal="showExampleModal = false" :full-hashtag="fullHashtag" />
  </template>
  <template v-if="showReviewTipsModal">
    <ReviewTipsModal
      @go-to-google-review="goToGoogleReview"
      :full-hashtag="fullHashtag"
      @close-review-tips-modal="showReviewTipsModal = false"
    />
  </template>
</template>

<script setup>
import { ref, watch, computed, onMounted, onUnmounted } from 'vue'

import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import IconCopy from '@/components/icons/IconCopy.vue'
import ExampleReviewModal from '@/pages/GoogleReview/Components/ExampleReviewModal.vue'
import ReviewTipsModal from '@/pages/GoogleReview/Components/ReviewTipsModal.vue'
import Timer from '@/pages/GoogleReview/Components/Timer.vue'
import { useBrandPortalStore } from '@/stores/store.js'

const hasClickedCopy = ref(false)
const hasClickedLeaveReview = ref(false)
const hasClickedValidate = ref(false)

const brandPortalStore = useBrandPortalStore()

const props = defineProps({
  reviewInfo: {
    type: Object,
    required: true
  },
  brandName: {
    type: String,
    required: true
  },
  currencyCode: {
    type: String,
    required: true
  },
  showLeaveReviewComponent: {
    type: Boolean,
    required: true
  },
  validatedNumberAndSpending: {
    type: Boolean
  }
})

const showExampleModal = ref(false)
const showReviewTipsModal = ref(false)
const showPhoneInputModal = ref(false)
const minSpendText = computed(() => {
  if (props.reviewInfo.minSpend === 0 || props.reviewInfo.minSpend === null) {
    return 'Validate your phone number'
  } else {
    return 'Spend ' + props.currencyCode + props.reviewInfo.minSpend + ' & validate'
  }
})

const fullHashtag = computed(() => {
  const uniqueHashtag = props.reviewInfo.uniqueHashtag
  const selectedHashtags = props.reviewInfo.selectedHashtags
  return `${uniqueHashtag} ${selectedHashtags}`.trim()
})

const reviewLink = computed(() => {
  return 'https://search.google.com/local/writereview?placeid=' + props.reviewInfo.placeId
})

const validateBtnText = computed(() =>
  props.validatedNumberAndSpending ? 'Validated' : 'Validate'
)

const copied = ref(false)

function copyToClipboard(text) {
  hasClickedCopy.value = true

  navigator.clipboard
    .writeText(text)
    .then(() => {
      copied.value = true
    })
    .catch((err) => {
      alert('Error copying text to clipboard:', err)
    })
}

const emit = defineEmits(['timer-ended', 'start-writing-clicked', 'leave-review-clicked'])

function handleTimerEnded() {
  emit('timer-ended')
}

function goToGoogleReview() {
  showReviewTipsModal.value = false
  window.open(reviewLink.value, '_blank')
  emit('leave-review-clicked')
}

const leaveReviewClicked = () => {
  hasClickedLeaveReview.value = true
  showReviewTipsModal.value = true
}

function startWritingClicked() {
  emit('start-writing-clicked')
  hasClickedValidate.value = true
}
</script>
