<template>
  <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9782 4.96939H7.44789L6.04775 0.661133L4.6582 6.35965L6.04775 9.27835L9.71376 11.9411L8.31363 7.63288L11.9782 4.96939Z"
      fill="#FABB05"
    />
    <path
      d="M4.64849 4.96939H0.118164L3.78416 7.63217L2.38333 11.9411L6.04933 9.27835V0.661133L4.64849 4.96939Z"
      fill="#FABB05"
    />
  </svg>
</template>
