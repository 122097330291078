import './assets/css/main.css'

// vue app
import { createApp, watch } from 'vue'

// router
import router from './router'

// pinia
import { createPinia } from 'pinia'

// urql
import urql from '@urql/vue'
import { client } from './client.js'

// vue cookies
import VueCookies from 'vue-cookies'

import cookie from '@/utils/cookie'

// date picker
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import axios from 'axios'

import App from './App.vue'

fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
  .then((response) => response.json())
  .then((meta) => {
    const latestVersion = meta.version
    const versionFromCookie = cookie.getSecureCookie('slAppVer', false)

    if (versionFromCookie != latestVersion) {
      cookie.setSecureCookie('slAppVer', latestVersion, true, true, false)
      if (caches) {
        caches.keys().then((names) => {
          for (let name of names) caches.delete(name)
        })
      }
      window.location.reload(true)
    }
  })

const fetchCsrfToken = async () => {
  try {
    await axios.get(`${process.env.VUE_APP_ENDPOINT}csrf_token`, {
      withCredentials: true
    })
  } catch (e) {
    console.error('Error fetching CSRF token:', e)
  }
}

fetchCsrfToken()

const app = createApp(App)

app.use(createPinia())
app.use(urql, client)
app.use(router)
app.use(VueCookies)
app.component('VueDatePicker', VueDatePicker)
app.mount('#app')
