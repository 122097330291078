import { parseISO, format } from 'date-fns'

// date format: dd/MM/yyyy
export const formatDate = (dateStr) => {
  const date = new Date(dateStr)

  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const year = date.getFullYear()

  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}

export const formatDateToPattern = (date, pattern = "eee, dd MMM yyyy 'at' h:mm aa") => {
  const parsedDate = parseISO(date)

  return format(parsedDate, pattern)
}
