<template>
  <div class="container min-h-[80vh] mt-6 mb-12">
    <template v-if="history.fetching.value">
      <h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">Loading...</h1>
    </template>
    <template v-else-if="showHistory === false">
      <h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">
        No history yet!
      </h1>
    </template>
    <template v-else-if="showHistory === true">
      <h1
        class="text-2xl font-poppinsBold text-center pb-2.5 border-b-2"
        :style="brandPortalStore.primaryTextColorStyle"
      >
        History
      </h1>
      <FlexCol class="pt-4 gap-y-2.5 overflow-y-auto pb-6 mb-14">
        <AccordianHistory
          v-for="message in historyLogs"
          :key="message.id"
          :message-id="message.id"
          :message-obj="message"
        />
      </FlexCol>
    </template>
  </div>
  <MobileFooter class="-mx-5" :active="'History'" />
</template>

<script setup>
import FlexCol from '@/components/layout/FlexCol.vue'
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import AccordianHistory from '@/pages/History/AccordianHistory.vue'
import { useQuery } from '@urql/vue'
import { useBrandStore } from '../../stores/store'
import { GET_HISTORY } from '../../constants/graphql'
import { ref, watch, computed } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'

const store = useBrandStore()
const brandPortalStore = useBrandPortalStore()
const historyLogs = ref([])
const showHistory = ref('')

const history = useQuery({
  query: GET_HISTORY,
  variables: {
    brandId: store.brandId,
    slug: store.slugUrl
  }
})

watch(history.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    let arr = []
    let type = ''
    let content = ''

    if (history.data.value.customerHistory.collectionsAndDeductions.length === 0) {
      showHistory.value = false
    } else {
      history.data.value.customerHistory.collectionsAndDeductions.forEach((i, index) => {
        if (i.void === true) {
          type = 'Voided'
          content = i.title
        } else if (i.specification === 'redemption') {
          type = 'Items Redeemed'
          content = i.title
        } else if (i.specification === 'collection') {
          type = 'Expiry'

          if (i.expiryDays < 0) {
            content = 'Expired'
          } else if (i.expiryDays === 0) {
            content = 'Expiring today'
          } else {
            content = 'In ' + i.expiryDays + ' day(s)'
          }
        }

        arr.push({
          id: index + 1,
          content: content,
          amount: i.amount,
          datetime: i.createdAt,
          type: type
        })
        showHistory.value = true
      })
    }
    historyLogs.value = sortedArray(arr).reverse()
  }
})

const sortedArray = (arr) => {
  let balance = 0
  return [...arr].reverse().map((item) => {
    if (item.type === 'Items Redeemed') {
      balance -= item.amount
    } else if (item.type === 'Expiry') {
      balance += item.amount
    }
    return { ...item, balance }
  })
}
</script>
