<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <FlexCol class="container min-h-screen" id="default-side-padding">
    <FlexCol style="min-height: inherit">
      <FlexRow class="justify-center">
        <FlexCol class="items-center">
          <h1 class="font-poppinsBold text-3xl text-primary-black mb-3 mt-10">
            Verify your mobile
          </h1>
          <p class="font-normal text-base text-icon-grey leading-tight text-center">
            We've sent an SMS with an activation code to your phone
            <span class="text-primary-black">{{ phoneNumber }}</span>
          </p>
          <div class="my-10 h-[4rem] text-center">
            <OTPInput
              :length="4"
              :otp-arr="otpCodeInput"
              :otp-is-valid="otpValid !== null ? otpValid : null"
            />
            <template v-if="otpValid !== null && otpValid === false">
              <div class="text-base text-[#f54135] font-normal mt-1">
                Wrong code, please try again
              </div>
            </template>
          </div>
          <FlexRow class="text-base text-icon-grey items-center mb-4 mt-3">
            <template v-if="!resentCode">
              <span class="font-bold me-2 whitespace-nowrap"> Didn't receive it? </span>
              <span
                class="font-normal text-mulah-red underline cursor-pointer whitespace-nowrap"
                @click="!resentCode ? handleResendCode() : null"
              >
                Send code to WhatsApp
              </span>
            </template>
            <template v-else>
              <span class="font-bold me-2"> Send code again </span>
              <span class="font-normal"> 00:{{ seconds < 10 ? '0' + seconds : seconds }} </span>
            </template>
          </FlexRow>
        </FlexCol>
      </FlexRow>
      <FlexRow>
        <FlexCol class="w-full gap-2.5 mt-20" :class="otpValid ? 'mb-8' : ''">
          <Button size="lg" @click="verifyOTP()" :style="brandPortalStore.primaryButtonStyles">
            Continue
          </Button>
        </FlexCol>
      </FlexRow>
    </FlexCol>
  </FlexCol>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import OTPInput from '@/components/uielements/OTPInput/OTPInput.vue'
import { useMutation } from '@urql/vue'
import { useRouter } from 'vue-router'
import { usePhoneStore, useBrandPortalStore } from '@/stores/store'
import { useBrandStore } from '../../stores/store'
import { ref, computed, onMounted } from 'vue'
import { CHECKPOINTS_VALIDATION, VERIFY_AUTH_CODE } from '@/constants/graphql.js'

const router = useRouter()
const phoneStore = usePhoneStore()
const brandPortalStore = useBrandPortalStore()
const store = useBrandStore()
const otpCodeInput = ref([])
const otpValid = ref(null)
const resentCode = ref(false)
const seconds = ref(20)
const phoneNumber = ref('')
const pause = ref(false)
const { executeMutation: executeCheckpointsValidation } = useMutation(CHECKPOINTS_VALIDATION)
const { executeMutation: executeVerifyAuthCode } = useMutation(VERIFY_AUTH_CODE)

onMounted(() => {
  if (phoneStore.fullPhoneNum) {
    phoneNumber.value = phoneStore.fullPhoneNum
  }
})

const OTPFilled = computed(() => {
  return otpCodeInput.value.every((item) => item !== '')
})

const verifyOTP = async () => {
  if (OTPFilled.value && pause.value === false) {
    pause.value = true

    const verify = await executeVerifyAuthCode({
      phoneNumber: phoneStore.trunkPrefix + phoneStore.phoneNumber,
      authCode: String(otpCodeInput.value.join(''))
    })
    if (!verify.error) {
      if (verify.data.slVerifyAuthCode.result === 'OK') {
        // eslint-disable-next-line no-undef
        $cookies.set(
          'phoneNumber',
          phoneStore.trunkPrefix + phoneStore.phoneNumber,
          '1y',
          null,
          null,
          true
        )
        otpValid.value = true

        phoneStore.$patch({
          isLogin: true,
          phoneNumberWithPrefix: phoneStore.trunkPrefix + phoneStore.phoneNumber
        })
      } else {
        otpValid.value = false
        otpCodeInput.value.length = 0
      }
    }
    pause.value = false

    if (phoneStore.locationPath !== '') {
      if (otpValid.value) {
        router.push({
          name: phoneStore.locationPath
        })
      }
    }
  }
}

const handleResendCode = async () => {
  otpValid.value = null
  resentCode.value = true

  // countdown timer
  let interval = setInterval(() => {
    seconds.value--
    if (seconds.value === 0) {
      clearInterval(interval)
      seconds.value = 20
      resentCode.value = false
    }
  }, 1000)

  if (pause.value === false) {
    pause.value = true
    const result = await executeCheckpointsValidation({
      phoneNumber: phoneStore.phoneNumber,
      slug: store.slugUrl,
      specification: 'ws_check',
      websiteToken: null,
      countryCode: phoneStore.selectedCountry
    })
    if (!result.error) {
      const data = result.data.slCheckpointsValidation

      if (data.errors.length > 0) {
        return alert(data.errors[0].message)
      }
    }
    pause.value = false
  }
}
</script>
