<template>
  <Card>
    <FlexCol class="gap-y-2 w-full">
      <FlexRow class="justify-between items-center">
        <h4 class="font-poppinsBold text-sm">{{ reward.title }}</h4>
        <h4 class="font-poppinsBold text-sm whitespace-nowrap">{{ reward.cost }} Points</h4>
      </FlexRow>
      <FlexRow class="my-1">
        <ProgressBar :width="getProgress" :color="brandPortalStore.membersInfoColor" />
      </FlexRow>
      <FlexRow class="justify-end">
        <p class="text-xs mb-1.5">
          <template v-if="getPointsLeft === 0">
            <span class="text-mulah-red">Ready to redeem!</span>
          </template>
          <template v-else>
            <span class="text-mulah-red">{{ getPointsLeft }}</span>
            points to go!
          </template>
        </p>
      </FlexRow>
    </FlexCol>
  </Card>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Card from '@/components/uielements/Card/Card.vue'
import ProgressBar from '@/components/uielements/ProgressBar/ProgressBar.vue'
import { useBrandPortalStore } from '@/stores/store.js'

import { computed } from 'vue'
const brandPortalStore = useBrandPortalStore()

const props = defineProps({
  reward: {
    type: Object,
    default: () => {}
  },
  userPoints: {
    type: Number,
    default: 0
  }
})

const getPointsLeft = computed(() => {
  let pointsLeft = props.reward.cost - props.userPoints
  if (pointsLeft < 0) pointsLeft = 0
  return pointsLeft
})

const getProgress = computed(() => {
  let progress = (props.userPoints / props.reward.cost) * 100
  if (progress > 100) progress = 100
  return progress
})
</script>
