<template>
  <div class="bg-zinc-900 w-full min-h-[90vh] flex flex-col items-center" id="default-side-padding">
    <Wheel style="height: 250px; width: 250px" />
    <h2 class="text-2xl mb-4 flex justify-center font-bold text-white">Spend. Spin. Win.</h2>
    <p class="text-stone-400 text-center">
      Spend, collect points and stand a chance to win prizes when you spin the wheel!
    </p>
    <div
      class="w-full h-auto bg-zinc-800 rounded-md flex flex-col items-center mt-10"
      style="border: 1px dotted white"
    >
      <p class="mt-4 text-xl font-extrabold text-white">How to participate?</p>
      <p class="text-gray-400 text-sm">To join, follow these steps:</p>
      <div class="flex flex-col items-start justify-start mt-2">
        <p class="mt-2 text-white">1. {{ minSpendText }}</p>
        <p class="mt-2 text-white">2. Spin the wheel!</p>
        <p class="mt-2 mb-8 text-white">
          3. Claim your prize if you won! <br /><span
            class="active-hover underline"
            @click="handleShowPrizes"
            >See Prizes</span
          >
        </p>
      </div>
    </div>
    <p
      class="active-hover mt-4 flex justify-center text-md font-bold text-white underline"
      @click="handleShowTnc"
    >
      *Terms & Conditions apply.
    </p>
    <p class="flex justify-center mt-2 text-gray-400 text-sm" v-html="eventMessage"></p>

    <Button
      class="text-white w-full mb-28 mt-10 rounded-lg h-12"
      variant="mulah"
      size="lg"
      @click="backToHome"
      >Back</Button
    >
  </div>
  <MobileFooter style="margin-left: 0.3px" />
  <TncModal v-if="openModal" :tnc="tnc" :minSpend="minSpend" @close-tnc="openModal = false" />
  <PrizeListModal
    v-if="showPrizeList"
    :prize-lists="prizeLists"
    :expiry="expiry"
    @close-prize="showPrizeList = false"
  />
</template>

<script setup>
import { computed, watch, ref } from 'vue'
import { useQuery } from '@urql/vue'

import { SL_ACTIVE_CHANCE } from '@/constants/graphql.js'
import { formatDate } from '@/utils/dateUtils.js'
import { useBrandStore } from '@/stores/store'
import { currencySymbol } from '@/constants/country_currency.js'
import router from '@/router/index.js'

import Button from '@/components/uielements/Button/Button.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import Wheel from '@/components/icons/IconWheel.vue'
import TncModal from '@/pages/Wheel/Component/ModalTnC.vue'
import PrizeListModal from '@/pages/Wheel/Component/ModalPrizeList.vue'

const brandId = computed(() => store.brandId)
const endDate = ref(null)
const expiry = ref(0)
const minSpend = ref(null)
const openModal = ref(false)
const prizeLists = ref([])
const store = useBrandStore()
const currSym = currencySymbol[store.countryCode]
const showPrizeList = ref(false)
const tnc = ref('')

const activeChance = useQuery({
  query: SL_ACTIVE_CHANCE,
  variables: {
    brandId: brandId
  },
  pause: computed(() => !store.brandId)
})

const backToHome = () => {
  router.push({ path: '/' })
}

const handleShowTnc = () => {
  openModal.value = true
}

const handleShowPrizes = () => {
  showPrizeList.value = true
}

const minSpendText = computed(() => {
  if (minSpend.value) {
    return `Spend at least ${currSym}${minSpend.value}`
  } else {
    return `Visit store & spend`
  }
})

const formatTnc = (tnc) => {
  if (!tnc) return ''
  return tnc.replace(/<b>Terms and Conditions<\/b>/i, '').trim()
}

watch(activeChance.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    const records = activeChance.data.value?.slActiveChance
    minSpend.value = records.minSpend
    tnc.value = formatTnc(records.termsAndConditions)
    endDate.value = records.endDate
    prizeLists.value = records.promotions
    expiry.value = records.expiry
  }
})

const eventMessage = computed(() => {
  return `This event is valid until&nbsp;<span class="text-red-500">${formatDate(
    endDate.value
  )}</span>`
})

const emit = defineEmits(['show-tnc', 'show-prizes'])
</script>

<style scoped>
.active-hover:hover {
  cursor: pointer;
}
</style>
