<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container min-h-[80vh] mt-6 mb-12">
    <template v-if="messageLogs.fetching.value"
      ><h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">
        Loading...
      </h1></template
    >
    <template v-else-if="!showMessage"
      ><h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">
        No messages yet!
      </h1></template
    >
    <template v-else-if="showMessage">
      <h1
        class="text-2xl font-poppinsBold text-center pb-2.5 border-b-2"
        :style="brandPortalStore.primaryTextColorStyle"
      >
        Messages Log
      </h1>
      <FlexCol class="pt-4 gap-y-2.5 overflow-y-auto pb-6 mb-14">
        <AccordianCard
          v-for="message in msgLogs"
          :key="message.id"
          :message-id="message.id"
          :message-obj="message"
        />
      </FlexCol>
    </template>
  </div>
  <MobileFooter class="-mx-5" :active="'Message Log'" />
</template>
<script setup>
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import AccordianCard from './AccordianCard.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import { useQuery } from '@urql/vue'
import { useBrandStore, useBrandPortalStore } from '../../stores/store'
import { GET_MESSAGE_LOGS } from '../../constants/graphql'
import { ref, watch, computed } from 'vue'

const store = useBrandStore()
const brandPortalStore = useBrandPortalStore()
const showMessage = ref('')
const msgLogs = ref([])

const messageLogs = useQuery({
  query: GET_MESSAGE_LOGS,
  variables: {
    brandId: store.brandId,
    hidden: false
  }
})

watch(messageLogs.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    let arr = []

    if (messageLogs.data.value.customerSmsLogs.length === 0) {
      showMessage.value = false
    } else {
      messageLogs.data.value.customerSmsLogs.forEach((i, index) => {
        arr.push({
          id: index + 1,
          sender: i.brandName,
          message: i.message,
          datetime: i.datetime,
          channel: i.channel
        })
      })
      msgLogs.value = arr
      showMessage.value = true
    }
  }
})
</script>

<style>
::-webkit-scrollbar {
  width: 0.75rem;
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background: #c9cdd4;
  border-radius: 0.4rem;
  border: 0.1rem solid #f1f1f1;
}
</style>
