<template>
  <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8244_25231)">
      <path
        d="M14.1 7.65625C14.1 7.16875 14.0563 6.7 13.975 6.25H7.5V8.9125H11.2C11.0375 9.76875 10.55 10.4937 9.81875 10.9812V12.7125H12.05C13.35 11.5125 14.1 9.75 14.1 7.65625Z"
        fill="#4285F4"
      />
      <path
        d="M7.4998 14.375C9.35605 14.375 10.9123 13.7625 12.0498 12.7125L9.81855 10.9812C9.20605 11.3937 8.4248 11.6437 7.4998 11.6437C5.7123 11.6437 4.19355 10.4375 3.6498 8.8125H1.3623V10.5875C2.49355 12.8313 4.8123 14.375 7.4998 14.375Z"
        fill="#34A853"
      />
      <path
        d="M3.65 8.80645C3.5125 8.39395 3.43125 7.95645 3.43125 7.5002C3.43125 7.04395 3.5125 6.60645 3.65 6.19395V4.41895H1.3625C0.89375 5.34395 0.625 6.3877 0.625 7.5002C0.625 8.6127 0.89375 9.65645 1.3625 10.5814L3.14375 9.19395L3.65 8.80645Z"
        fill="#FBBC05"
      />
      <path
        d="M7.49981 3.3625C8.51231 3.3625 9.4123 3.7125 10.1311 4.3875L12.0998 2.41875C10.9061 1.30625 9.35606 0.625 7.49981 0.625C4.8123 0.625 2.49355 2.16875 1.3623 4.41875L3.6498 6.19375C4.19356 4.56875 5.7123 3.3625 7.49981 3.3625Z"
        fill="#EA4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_8244_25231">
        <rect width="15" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
