<template>
  <div class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
    <div
      class="h-5/6 bg-black rounded-lg shadow-lg p-6 w-11/12 sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/3 2xl:w-1/4 overflow-auto"
    >
      <div class="flex items-center justify-evenly mb-10">
        <h2 class="text-white text-3xl font-bold text-center">Prizes for this event</h2>
        <button class="text-white text-base font-normal focus:outline-none" @click="closeModal">
          ✕
        </button>
      </div>
      <Voucher v-for="prize in prizeLists" :key="prize.id" class="w-full h-auto relative mb-6">
        <p
          class="top-5 left-10 absolute w-full text-black font-bold flex justify-start text-xl z-50"
        >
          {{ prize.title }}
        </p>
        <p class="top-10 left-10 absolute w-full text-black flex justify-start text-sm z-50 mt-3">
          *Expires {{ expiry }} days after winning
        </p>
      </Voucher>
    </div>
  </div>
</template>

<script setup>
import Voucher from '@/components/icons/IconVoucher.vue'

const closeModal = () => {
  emit('close-prize')
}

const props = defineProps({
  prizeLists: {
    type: Array,
    required: true
  },
  expiry: {
    type: Number,
    required: true
  }
})

const emit = defineEmits(['close-prize'])
</script>
