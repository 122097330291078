<template>
  <Card class="pt-1">
    <AccordianCollapse :accordian-id="'accordion-' + messageId">
      <AccordianHeading
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2.5 justify-between"
      >
        <span class="font-poppinsBold text-sm" :style="brandPortalStore.textLinkColorStyle">{{
          formatDateToPattern(props.messageObj.datetime)
        }}</span>
      </AccordianHeading>
      <AccordianBody
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2"
      >
        <Card class="shadow-none">
          <FlexCol class="gap-y-1.5">
            <FlexRow class="gap-x-2 items-center">
              <p
                class="basis-1/4 text-end font-inter text-xs"
                :style="brandPortalStore.secondaryTextColorStyle"
              >
                From:
              </p>
              <p
                class="basis-3/4 font-inter text-xs"
                :style="brandPortalStore.primaryTextColorStyle"
              >
                {{ messageObj.sender }}
              </p>
            </FlexRow>
            <FlexRow class="gap-x-2 items-center">
              <p
                class="basis-1/4 text-end font-inter text-xs"
                :style="brandPortalStore.secondaryTextColorStyle"
              >
                Date:
              </p>
              <p
                class="basis-3/4 font-inter text-xs"
                :style="brandPortalStore.primaryTextColorStyle"
              >
                {{ formatDateToPattern(props.messageObj.datetime, "dd MMM yyyy',' h:mm aa") }}
              </p>
            </FlexRow>
            <!-- TODO: Add in future, hide this for now as our DB does not store campaigns -->
            <!-- <FlexRow class="gap-x-2 items-start">
              <p class="basis-1/4 text-end font-inter text-xs text-secondary-grey">Campaign:</p>
              <p class="basis-3/4 font-inter text-xs text-primary-black">
                {{ messageObj.campaign }} &lt;Personal Message&gt;
              </p>
            </FlexRow> -->
            <FlexRow class="gap-x-2 items-center">
              <p
                class="basis-1/4 text-end font-inter text-xs"
                :style="brandPortalStore.secondaryTextColorStyle"
              >
                Type:
              </p>
              <p
                class="basis-3/4 font-inter text-xs"
                :style="brandPortalStore.primaryTextColorStyle"
              >
                {{
                  messageObj.channel === 'whatsapp'
                    ? messageObj.channel.charAt(0).toUpperCase() + messageObj.channel.slice(1)
                    : 'SMS'
                }}
              </p>
            </FlexRow>
          </FlexCol>
        </Card>
      </AccordianBody>
    </AccordianCollapse>
    <p class="px-1 text-xs my-2" :style="brandPortalStore.primaryTextColorStyle">
      {{ messageObj.message }}
    </p>
    <FlexRow class="px-1 mt-2 items-center justify-end">
      <span class="text-primary-black text-xs font-inter">Sent on</span>
      <template v-if="messageObj.channel === 'whatsapp'">
        <span class="ms-1 text-[#0EC143]"><IconWhatsapp /></span>
      </template>
      <template v-else>
        <span class="text-mulah-red font-inter text-xs font-semibold ms-1">SMS</span>
      </template>
    </FlexRow>
  </Card>
</template>
<script setup>
import AccordianCollapse from '@/components/uielements/Accordian/AccordianCollapse.vue'
import AccordianHeading from '@/components/uielements/Accordian/AccordianHeading.vue'
import AccordianBody from '@/components/uielements/Accordian/AccordianBody.vue'
import Card from '@/components/uielements/Card/Card.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconWhatsapp from '@/components/icons/IconWhatsapp.vue'
import { computed, defineProps } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'
import { formatDateToPattern } from '@/utils/dateUtils.js'

const brandPortalStore = useBrandPortalStore()

const props = defineProps({
  messageId: {
    type: [String, Number],
    required: true
  },
  messageObj: {
    type: Object,
    required: true
  }
})
</script>
