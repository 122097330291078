<template>
  <FlexCol class="container min-h-screen overflow-y-auto">
    <div class="tnc" v-html="tnc"></div>
  </FlexCol>
  <MobileFooter class="-mx-5" />
</template>

<script setup>
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import { useBrandStore } from '@/stores/store'
import { computed } from 'vue'

const store = useBrandStore()

const tnc = computed(() => {
  return store.tnc
})
</script>
<style scoped>
.tnc ::v-deep(b) {
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(31 31 31 / var(--tw-text-opacity));
  font-family: Poppins-Bold, sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tnc ::v-deep(ol) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  list-style-type: decimal;
}

.tnc ::v-deep(li) {
  --tw-text-opacity: 1;
  color: rgb(69 69 69 / var(--tw-text-opacity));
  font-size: 0.875rem;
  line-height: 1.25rem;
}
</style>
