<template>
  <FlexCol class="w-full">
    <h3
      v-if="props.links.length !== 0"
      class="font-bold font-inter my-2 text-lg"
      :style="brandPortalStore.primaryTextColorStyle"
    >
      Links
    </h3>
    <a
      v-for="link in links"
      :key="link.title"
      :href="formatUrl(link.url)"
      target="_blank"
      @click="createUrlClick(link.specification)"
      :disabled="link.url === '' || link.url === null"
    >
      <div
        class="rounded-full border pt-3.5 pb-3.5 mb-3 flex items-center"
        style="cursor: pointer; background: white; border-color: gray"
      >
        <span v-if="link.emoji" class="text-2xl ps-5" v-html="link.emoji"></span>
        <span v-if="!link.emoji" class="mr-3" style="width: 35px"></span>

        <p class="flex-1 text-center pe-3" :style="brandPortalStore.primaryTextColorStyle">
          <span>{{ link.title }}</span>
        </p>
        <span class="mr-3" style="width: 30px; height: 30px"></span>
      </div>
    </a>
  </FlexCol>
</template>

<script setup>
import FlexCol from '@/components/layout/FlexCol.vue'
import { ref, computed } from 'vue'
import { useMutation } from '@urql/vue'
import { useBrandStore, useBrandPortalStore } from '../../stores/store'
import { CREATE_URL_CLICK } from '@/constants/graphql'

const store = useBrandStore()
const brandPortalStore = useBrandPortalStore()
const pause = ref(false)
const { executeMutation } = useMutation(CREATE_URL_CLICK)

const props = defineProps({
  links: {
    type: Object,
    required: true
  }
})

const createUrlClick = async (spec) => {
  if (pause.value === false) {
    pause.value = true
    await executeMutation({
      id: store.brandId,
      specification: spec
    })
    pause.value = false
  }
}

const formatUrl = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url
  }
  return url
}
</script>

<style scoped>
a[disabled='true'] div {
  background: grey;
  color: white;
  opacity: 0.75;
}
</style>
